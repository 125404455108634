import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { API_RESOURCE_PAYMENT_TRANSACTION } from 'Consts/apiResources';
import { 
    PAYMENT_TRANSACTION_STATUS_PENDING,
    PAYMENT_TRANSACTION_STATUS_COMPLETED,
    PAYMENT_TRANSACTION_STATUS_ERROR,
    PAYMENT_TRANSACTION_STATUS_CANCELED,
    PAYMENT_TRANSACTION_STATUS_UNDEFINED,
} from 'Consts/paymentTransactions';
import { ADEPT_EVENTS, ADEPT_DASHBOARD, PUBLIC_SHOP_USER_OFFER } from 'Consts/routes';
import { TRACKING_EVENT_TRANSACTION_COMPLETED } from 'Consts/tracking';

import { getStatusLabel } from 'Utils/paymentTransaction';
import { withVariables } from 'Utils/string';

import { trackEvent } from 'Services/Tracking';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import Button from 'Components/layout/Button';

export default class AdeptPaymentTransactionsVerify extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            verify: PropTypes.func.isRequired,
        }).isRequired,
        transactionId: PropTypes.string,
    };
    static defaultProps = {
        predefinedQuery: {},
    };

    state = {
        paymentTransaction: null,
        currentInterval: 0,
        maxIntervalCount: 5,
    }
    loadInterval = null;

    componentDidMount = () => {
        this.loadData();

        this.loadInterval = setInterval(() => {
            this.loadData();
            this.setState(prevState => ({
                currentInterval: prevState.loadicurrentIntervalngTime + 1,
            }));
        }, 5000);
    }

    componentDidUpdate = prevProps => {
        const { transactionId } = this.props;
        const { currentInterval, maxIntervalCount } = this.state;

        if (currentInterval >= maxIntervalCount) {
            return clearInterval(this.loadInterval);
        } 

        if (transactionId !== prevProps.transactionId) {
            this.loadInterval = setInterval(() => {
                this.loadData();
            }, 5000);
        }
    }

    loadData = () => {
        const { actions, transactionId, history } = this.props;

        actions.verify({ id: transactionId })
            .then(response => {
                const paymentTransaction = response.payload[API_RESOURCE_PAYMENT_TRANSACTION];
                if (paymentTransaction.status !== PAYMENT_TRANSACTION_STATUS_PENDING) {
                    clearInterval(this.loadInterval);
                    this.setState({ paymentTransaction });
                }
                
                if (paymentTransaction.status === PAYMENT_TRANSACTION_STATUS_COMPLETED) {
                    trackEvent(TRACKING_EVENT_TRANSACTION_COMPLETED, {
                        transactionId: paymentTransaction.id,
                        transactionTotal: paymentTransaction.value,
                        transactionProducts: paymentTransaction.userOffer?.name,
                    });
                }

                if ([PAYMENT_TRANSACTION_STATUS_ERROR, PAYMENT_TRANSACTION_STATUS_CANCELED, PAYMENT_TRANSACTION_STATUS_UNDEFINED].includes(paymentTransaction?.status)) {
                    history.push(
                        withVariables(
                            PUBLIC_SHOP_USER_OFFER.path,
                            { id: paymentTransaction.userOfferId },
                            { payment: 'failure' },
                        )
                    );
                } 
            });
    }

    render() {
        const { paymentTransaction, currentInterval, maxIntervalCount } = this.state;

        return (
            <StyledComponent
                className="adept-payment-transactions-verify"
                styles={require('./styles')}
            >
                {!paymentTransaction && (
                    currentInterval < maxIntervalCount 
                        ? (
                            <Spinner text="Weryfikujemy Twoją płatność" />
                        )
                        : (
                            <div className="notification-wrapper"> 
                                <h3 className="headline"> Weryfikacja Twojej płatności może zająć nieco dłużej </h3>
                                <a className="subtitle">
                                    O statusie Twojej płatności poinformujemy Cię drogą mailową.  
                                </a>
                                <Link to={ADEPT_DASHBOARD.path}>
                                    <Button
                                        style="hollow"
                                        size="large"
                                    >
                                        Wróć do panelu
                                    </Button>
                                </Link>
                            </div>
                        )
                )}

                {paymentTransaction && paymentTransaction.status == PAYMENT_TRANSACTION_STATUS_PENDING && (
                    <div className="status">
                        <div className="status-text">
                            Twoja płatność jest w trakcie realizacji.
                        </div>
                        <Link to={ADEPT_EVENTS.path}>
                            <Button
                                style="gradient"
                                size="large"
                            >
                                Wróć do panelu
                            </Button>
                        </Link>
                    </div>
                )}
                
                {paymentTransaction && paymentTransaction.status == PAYMENT_TRANSACTION_STATUS_COMPLETED && (
                    <div className="status">
                        <div className="status-text">
                            Status Twojej płatności: {getStatusLabel(paymentTransaction).label}
                        </div>
                        <Link to={ADEPT_DASHBOARD.path}>
                            <Button
                                style="gradient"
                                size="large"
                            >
                                Przejdź do panelu
                            </Button>
                        </Link>
                    </div>
                )}

                {paymentTransaction && paymentTransaction.status == PAYMENT_TRANSACTION_STATUS_ERROR && (
                    <div className="status">
                        <div className="status-text">
                            Twoja płatność nie powiodła się. 
                        </div>
                        <Link to={ADEPT_EVENTS.path}>
                            <Button
                                style="gradient"
                                size="large"
                            >
                                Wróć do panelu
                            </Button>
                        </Link>
                    </div>
                )}

                {paymentTransaction && paymentTransaction.status == PAYMENT_TRANSACTION_STATUS_CANCELED && (
                    <div className="status">
                        <div className="status-text">
                            Twoja płatność została anulowana. 
                        </div>
                        <Link to={ADEPT_EVENTS.path}>
                            <Button
                                style="gradient"
                                size="large"
                            >
                                Wróć do panelu
                            </Button>
                        </Link>
                    </div>
                )}  

                {paymentTransaction && paymentTransaction.status == PAYMENT_TRANSACTION_STATUS_UNDEFINED && (
                    <div className="status">
                        <div className="status-text">
                            Wystąpił błąd, spróbuj ponownie później.
                        </div>
                        <Link to={ADEPT_EVENTS.path}>
                            <Button
                                style="gradient"
                                size="large"
                            >
                                Wróć do panelu
                            </Button>
                        </Link>
                    </div>
                )}  
            </StyledComponent>
        );
    }
}
