import { connect } from 'react-redux';
import Component from './component';

import {
    verify,
} from 'Redux/modules/public/paymentTransactions/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            verify: dispatch(verify),
        },
    }),
)(Component);