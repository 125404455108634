import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .progress-container {
        margin: 4em auto;
        max-width: 40em;
    }

    .content-container {
        margin-top: 6em;
    }

    @media (max-width: ${variables.tabletL}) {}
    @media (max-width: ${variables.mobileL}) {}
`;

