import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .component-spinner {
        position: relative;
        padding: 5em;
        font-size: 2em;
    }

    .notification-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100vh;

        .headline {
            font-weight: 600;
            font-size: 2em;
            color: ${variables.dpc_fontDark};
            margin-bottom: .7em;
        }

        .subtitle {
            font-weight: 300;
            font-size: 1.2em;
            color: ${variables.dpc_fontDark};
            margin-bottom: 3em;
        }
    }

    .status {
        padding: 5em;

        .status-text {
            text-align: center;
            font-size: 2em;
            color: ${variables.dpc_fontDark};
        }
        .component-button {
            margin-top: 2em;
            display: flex;
            justify-content: center;
        }
    }
`;
