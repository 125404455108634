import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { parseQueryToObject } from 'Utils/querystring';

import Head from 'Components/core/Head';
import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Page from 'Components/layout/panel/Page';
import TransactionVerify from 'Components/adept/paymentTransactions/Verify';

export default class PublicShopPayment extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    render() {
        const { location, history } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search);

        return (
            <StyledComponent
                className="public-shop-payment"
                styles={require('./styles')}
            >
                <Head title="Sklep - płatność" />
                <Page>
                    <LayoutContainer>
                        <TransactionVerify
                            location={location}
                            history={history}
                            transactionId={queryObject.transactionId}
                        />
                    </LayoutContainer>
                </Page>
            </StyledComponent>
        );
    }
}
